<template>
  <div class="home-container">
	
	<div class="home">
		<div v-if="showDialog" class="gonggao">
			<div class="ggtitle">系统公告</div>
			<div class="gonggao-content">
				<h3 class="ggtctitle">{{ gonggaoTitle }}</h3>
				<p>{{ gonggaoDetails }}</p>
				<p class="ggtime">{{ gonggaoTime }}</p>
				<button @click="closeModal" class="ggbtn">确认</button>
			</div>
		</div>
		<div v-if="showDialog" class="gonggao-overlay"></div>
	</div>
	
  </div>
</template>

<script>
export default {
  data() {
    return {
	showDialog: false,
	gonggaoTitle: "",
	gonggaoDetails: ""
    };
  },
  methods: {
	// showModal() {
	// 	this.showDialog = true;
	// 	// 动态渲染数据
	// 	this.gonggaoTitle = "天美社区源码网";
	// 	this.gonggaoDetails = "本平台所有资源真实，同城约炮选取你心仪的她，与您零距离邂逅";
	// 	this.gonggaoTime = "2024-01-07 12:34:56";
	// },
	closeModal() {
		this.showDialog = false;
		this.$http({
			method: 'get',
			url: 'sys_get_user_upnotice_list'
		}).then(timibbs=>{
			console.log(timibbs);
			
		})
	},
	getUserNotice() {
		this.$http({
			method: 'get',
			url: 'sys_get_user_notice_list'
		}).then(timibbs=>{
			console.log(timibbs);
			if(timibbs.code == 200){
				this.showDialog = true;
				this.gonggaoTitle = timibbs.data.name;
				this.gonggaoDetails = timibbs.data.text;
				this.gonggaoTime = timibbs.data.create_time;
			}
			
		})
	},
  },
  created() {
	// 设置定时器每500ms触发一次
	setInterval(() => {
		this.getUserNotice();
	}, 5000);
  }
}

</script>

<style lang='less' scoped>
.ggtctitle{
	margin-top: 10vw;
	text-align: left;
}
.ggbtn{
	height: 9vw;
	width: 18vw;
	background: linear-gradient(#fde3ca, #e7b486);
	border: navajowhite;
	border-radius: 5px;
	margin-top: 6vw;
}
.ggtitle{
	background: #343434;
	height: 9vw;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	color: #ddb285;
	font-size: 6vw;
}
.ggtime {
	text-align: right;
    font-size: 3vw;
	/* margin-top: 11vw; */
}

.gonggao {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2.667vw;
  z-index: 9999;
  width: 70%;
  text-align: center;
  height: 35%;
}

.gonggao-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

</style>
